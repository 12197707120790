import { mapGetters } from 'vuex'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isAutoActive: {
      type: Boolean,
      default: false
    },
    isScrolling: {
      type: Boolean,
      default: false
    },
    requirePermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeState: false,
      debounce: null,
      isFirstRender: true
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  mounted() {
    this.activeState = this.isActive
  },
  watch: {
    isScrolling(value) {
      if (!this.isFirstRender) return
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.isAutoActive && !value && this.isFirstRender) {
          this.activeState = true
          this.isFirstRender = false
        }
      }, 500)
    }
  },
  methods: {
    showContent() {
      if (!this.requirePermission) {
        this.activeState = !this.activeState
      } else {
        this.$emit('showContent')
      }
    }
  }
}
