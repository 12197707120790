import { render, staticRenderFns } from "./ActivityCard.vue?vue&type=template&id=1b2f4aa3"
import script from "./js/activity-card.js?vue&type=script&lang=js&external"
export * from "./js/activity-card.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports